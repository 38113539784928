import Vue from 'vue';
import VueGtm from 'vue-gtm';

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_API_KEY,
  defer: true,
  compatibility: true,
  enabled: true,
  debug: process.env.NODE_ENV === 'development',
  loadScript: true,
});

export default VueGtm;
