/* eslint-disable */
import VueFormulate from '@braid/vue-formulate';
import Vue from 'vue';
import './assets/svg/icons.js';
import './assets/tailwind.css';
import './css/tailwind.css';
import cookies from './lib/cookies';
import vueAwesomeCountdown from './lib/countdown';
import formulateConfig from './lib/formulate/config';
import gtm from './lib/gtm';
import VueSanitize from './lib/sanitize';
import webstorage from './lib/webstorage';
import store from './store';


formulateConfig.locale=window.document.documentElement.attributes[0].value;

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  gtm,
  cookies,
  store,
  webstorage,
  vueAwesomeCountdown,
  VueSanitize,
  components: {
    KfAccordion: () => import(/* webpackChunkName: "main" */ '@/components/accordion/Accordion.vue'),
    AccordionItem: () => import(/* webpackChunkName: "main" */ '@/components/accordion/AccordionItem.vue'),
    BrowserMessage: () => import(/* webpackChunkName: "main" */ '@/components/BrowserMessage.vue'),
    ComparisonColumns: () => import(/* webpackChunkName: "main" */ '@/components/ComparisonColumns.vue'),
    ContactOverviewHeader: () => import(/* webpackChunkName: "main" */ '@/components/ContactOverviewHeader.vue'),
    CookieSetter: () => import(/* webpackChunkName: "main" */ '@/components/CookieSetter.vue'),
    FieldsControlButtons: () => import(/* webpackChunkName: "main" */ '@/components/forms/fields/FieldsControlButtons.vue'),
    FormContact: () => import(/* webpackChunkName: "main" */ '@/components/forms/FormContact.vue'),
    HeroHeader: () => import(/* webpackChunkName: "main" */ '@/components/heroheader/HeroHeader.vue'),
    HeroHeaderHome: () => import(/* webpackChunkName: "main" */ '@/components/heroheader/HeroHeaderHome.vue'),
    HeroHeaderVideo: () => import(/* webpackChunkName: "main" */ '@/components/heroheader/HeroHeaderVideo.vue'),
    HtmlRenderer: () => import(/* webpackChunkName: "main" */ '@/components/HtmlRenderer.vue'),
    GoToTopButton: () => import(/* webpackChunkName: "main" */ '@/components/GoToTopButton.vue'),
    SocialLinks: () => import(/* webpackChunkName: "main" */ '@/components/SocialLinks.vue'),
    NavigationSub: () => import(/* webpackChunkName: "main" */ '@/components/NavigationSub.vue'),
    ChatEntrance: () => import(/* webpackChunkName: "main" */ '@/components/livechat/ChatEntrance.vue'),
    KfBreadcrumb: () => import(/* webpackChunkName: "main" */ '@/components/breadcrumb/Breadcrumb.vue'),
    KfButton: () => import(/* webpackChunkName: "main" */ '@/components/base/button/Button.vue'),
    KfButtonContainer: () => import(/* webpackChunkName: "main" */ '@/components/button-container/ButtonContainer.vue'),
    KfCalculationTool: () => import(/* webpackChunkName: "main" */ '@/components/calculation-tool/CalculationTool.vue'),
    KfCard: () => import(/* webpackChunkName: "main" */ '@/components/card/Card.vue'),
    KfCarousel: () => import(/* webpackChunkName: "main" */ '@/components/carousel/Carousel.vue'),
    KfContactOverviewHeader: () => import(/* webpackChunkName: "main" */ '@/components/ContactOverviewHeader.vue'),
    KfContainer: () => import(/* webpackChunkName: "main" */ '@/components/layout/container/Container.vue'),
    KfCookieConsent: () => import(/* webpackChunkName: "main" */ '@/components/cookie-consent/CookieConsent.vue'),
    KfCurve: () => import(/* webpackChunkName: "main" */ '@/components/base/curve/Curve.vue'),
    KfFooter: () => import(/* webpackChunkName: "main" */ '@/components/footer/Footer.vue'),
    KfFooterSecondary: () => import(/* webpackChunkName: "main" */ '@/components/footer/FooterSecondary.vue'),
    KfIconList: () => import(/* webpackChunkName: "main" */ '@/components/icon-list/IconList.vue'),
    KfHeading: () => import(/* webpackChunkName: "main" */ '@/components/base/heading/Heading.vue'),
    KfHeader: () => import(/* webpackChunkName: "main" */ '@/components/header/header/Header.vue'),
    KfHeaderFullWidth: () => import(/* webpackChunkName: "main" */ '@/components/header/headerfullwidth/HeaderFullWidth.vue'),
    KfHeaderContent: () => import(/* webpackChunkName: "main" */ '@/components/header/headercontent/HeaderContent.vue'),
    KfHeaderNestedContent: () => import(/* webpackChunkName: "main" */ '@/components/header/headernestedcontent/HeaderNestedContent.vue'),
    KfFeaturedContent: () => import(/* webpackChunkName: "main" */ '@/components/featured-content/FeaturedContent.vue'),
    KfHighlightsList: () => import(/* webpackChunkName: "main" */ '@/components/highlights-list/HighlightsList.vue'),
    KfIconList: () => import(/* webpackChunkName: "main" */ '@/components/icon-list/IconList.vue'),
    KfIcon: () => import(/* webpackChunkName: "main" */ '@/components/base/icon/Icon.vue'),
    KfImage: () => import(/* webpackChunkName: "main" */ '@/components/base/image/Image.vue'),
    KfLink: () => import(/* webpackChunkName: "main" */ '@/components/base/link/Link.vue'),
    KfList: () => import(/* webpackChunkName: "main" */ '@/components/base/list/List.vue'),
    KfLocator: () => import(/* webpackChunkName: "main" */ '@/components/locator/Locator.vue'),
    KfLocatorRegionList: () => import(/* webpackChunkName: "main" */ '@/components/locator/LocatorRegionList.vue'),
    KfLocatorResultsList: () => import(/* webpackChunkName: "main" */ '@/components/locator/LocatorResultsList.vue'),
    KfLocatorResultsMap: () => import(/* webpackChunkName: "main" */ '@/components/locator/LocatorResultsMap.vue'),
    KfLogo: () => import(/* webpackChunkName: "main" */ '@/components/base/logo/Logo.vue'),
    KfMapWidget: () => import(/* webpackChunkName: "main" */ '@/components/map-widget/MapWidget.vue'),
    KfNewsFilters: () => import(/* webpackChunkName: "main" */ '@/components/news/news-filters/NewsFilters.vue'),
    KfNewsPagination: () => import(/* webpackChunkName: "main" */ '@/components/news/news-pagination/NewsPagination.vue'),
    KfPhotos: () => import(/* webpackChunkName: "main" */ '@/components/photos/Photos.vue'),
    KfQuote: () => import(/* webpackChunkName: "main" */ '@/components/quote/Quote.vue'),
    KfQuoteImage: () => import(/* webpackChunkName: "main" */ '@/components/quote/QuoteImage.vue'),
    KfQuoteText: () => import(/* webpackChunkName: "main" */ '@/components/quote/QuoteText.vue'),
    KfRelatedArticles: () => import(/* webpackChunkName: "main" */ '@/components/related-articles/RelatedArticles.vue'),
    KfRichText: () => import(/* webpackChunkName: "main" */ '@/components/base/rich-text/RichText.vue'),
    KfRow: () => import(/* webpackChunkName: "main" */ '@/components/layout/row/Row.vue'),
    KfSearchResults: () => import(/* webpackChunkName: "main" */ '@/components/search/SearchResults.vue'),
    KfSocialSharing: () => import(/* webpackChunkName: "main" */ '@/components/social-sharing/SocialSharing.vue'),
    KfSpacer: () => import(/* webpackChunkName: "main" */ '@/components/base/spacer/Spacer.vue'),
    KfUspList: () => import(/* webpackChunkName: "main" */ '@/components/usp-list/UspList.vue'),
    KfTextOneColumn: () => import(/* webpackChunkName: "main" */ '@/components/content/text/TextOneColumn.vue'),
    KfTextImageTwoColumn: () => import(/* webpackChunkName: "main" */ '@/components/content/text-image/TextImageTwoColumn.vue'),
    KfWizardApplication: () => import(/* webpackChunkName: "main" */ '@/components/wizards/application/WizardApplication.vue'),
    KfWizardLocationServiceTour: () => import(/* webpackChunkName: "main" */ '@/components/wizards/location-service-tour/WizardLocationServiceTour.vue'),
    KfWizardTso: () => import(/* webpackChunkName: "main" */ '@/components/wizards/tso/WizardTso.vue'),
    KfWizardRequestTikkie: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardRequestTikkie.vue'),
    LatestNewsWidget: () => import(/* webpackChunkName: "main" */ '@/components/LatestNewsWidget.vue'),
    LatestNewsWidgetItem: () => import(/* webpackChunkName: "main" */ '@/components/LatestNewsWidgetItem.vue'),
    LatestSocialPosts: () => import(/* webpackChunkName: "main" */ '@/components/LatestSocialPosts.vue'),
    KfVideo: () => import(/* webpackChunkName: "main" */ '@/components/video/Video.vue'),
    TheHeader: () => import(/* webpackChunkName: "main" */ '@/components/TheHeader.vue'),
    WelcomeBackgroundShield: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeBackgroundShield.vue'),
    WelcomeCountdown: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeCountdown.vue'),
    WelcomeFaqAccordion: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeFaqAccordion.vue'),
    WelcomeLocation: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeLocation.vue'),
    WelcomeSupportTeam: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeSupportTeam.vue'),
    WelcomeTimeline: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeTimeline.vue'),
    WelcomeTitleAdvanced: () => import(/* webpackChunkName: "main" */ '@/components/welcome/WelcomeTitleAdvanced.vue'),
    WizardAddChild: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardAddChild.vue'),
    WizardChangeCareStartingDate: () => import(/* webpackChunkName: "main" */ './components/wizards/WizardChangeCareStartingDate.vue'),
    WizardCancelContract: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardCancelContract.vue'),
    WizardCancelUpcomingContract: () => import(/* webpackChunkName: "main" */ './components/wizards/WizardCancelUpcomingContract.vue'),
    WizardChangeDaycareDays: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardChangeDaycareDays.vue'),
    WizardChangeLocation: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardChangeLocation.vue'),
    WizardDeferralOfPayment: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardDeferralOfPayment.vue'),
    WizardChildcareAllowance: () => import(/* webpackChunkName: "main" */ '@/components/wizards/childcare-allowance/WizardChildcareAllowance.vue'),
    WizardLessDaycare: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardLessDaycare.vue'),
    WizardMoreDaycare: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardMoreDaycare.vue'),
    KfWizardNewbornChild: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardNewbornChild.vue'),
    WizardNoChildBenefits: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardNoChildBenefits.vue'),
    WizardOverview: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardOverview.vue'),
    WizardPaymentArrangement: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardPaymentArrangement.vue'),
    KfWizardRequestData: () => import(/* webpackChunkName: "main" */ './components/wizards/WizardRequestData.vue'),
    KfWizardRequestDirectDebitAuthorization: () => import(/* webpackChunkName: "main" */ './components/wizards/WizardRequestDirectDebitAuthorization.vue'),
    WizardThroughputKdvBso: () => import(/* webpackChunkName: "main" */ '@/components/wizards/WizardThroughputKdvBso.vue'),
  },
});
Vue.use(VueFormulate, formulateConfig);
